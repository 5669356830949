import request from '@/utils/request'

export default {
    info: (params) => {
        return request({
            url: `/dev-api/eye/api/user/info`,
            method: 'get',
            params: params
        })
    },
    toast: (params) => {
        return request({
            url: `/dev-api/eye/api/toast`,
            method: 'get',
            params: params
        })
    },
    record: (params) => {
        return request({
            url: `/dev-api/eye/api/examined/record`,
            method: 'get',
            params: params
        })
    },
    examineMeta: (params) => {
        return request({
            url: `/dev-api/eye/api/examine/meta`,
            method: 'get',
            params: params
        })
    },
    trainingPlan: (params) => {
        return request({
            url: `/dev-api/eye/api/training/plan`,
            method: 'get',
            params: params
        })
    },
    trainingInfo: (params) => {
        return request({
            url: `/dev-api/eye/api/training/info`,
            method: 'get',
            params: params
        })
    },
    trainingLog: (params) => {
        return request({
            url: `/dev-api/eye/api/training/log`,
            method: 'get',
            params: params
        })
    },
    visionList: (params) => {
        return request({
            url: `/dev-api/eye/api/vision/list`,
            method: 'get',
            params: params
        })
    },
    result: (params) => {
        return request({
            url: `/dev-api/eye/api/examine/result`,
            method: 'post',
            data: params
        })
    },
    edit: (params) => {
        return request({
            url: `/dev-api/eye/api/user/edit`,
            method: 'post',
            data: params
        })
    },
    subLog: (params) => {
        return request({
            url: `/dev-api/eye/api/training/log`,
            method: 'post',
            data: params
        })
    },
    vision: (params) => {
        return request({
            url: `/dev-api/eye/api/vision`,
            method: 'post',
            data: params
        })
    },
    visionFlag: (params) => {
        return request({
            url: `/dev-api/eye/api/vision/flag`,
            method: 'get',
            params: params
        })
    },
}