<template>
  <div class="home">
    <div class="header">
      <img class="logo" src="@/assets/images/logo.png" alt="亿目佳" />
      <div>
        <a-menu
          v-model:selectedKeys="current"
          mode="horizontal"
          @click="changeKey"
        >
          <a-menu-item key="1"> {{ userName }} </a-menu-item>
          <a-menu-item key="2"> 首页 </a-menu-item>
        </a-menu>
        <poweroff-outlined @click="logout" />
      </div>
    </div>
    <div class="content">
      <div>
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import { PoweroffOutlined } from "@ant-design/icons-vue";
import { useRouter, useRoute } from "vue-router";
import api from "@/apis/dataApi.js";
export default defineComponent({
  components: {
    PoweroffOutlined,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const current = ref(["2"]);
    const userName = ref("个人中心");
    const changeKey = (data) => {
      switch (Number(data.key)) {
        case 1:
          router.push("./personal");
          break;
        case 2:
          router.push("./home");
          break;
      }
    };

    const getInfo = () => {
      api.info().then((res) => {
        userName.value = res.data.userName;
      });
    };

    onMounted(() => {
      if (route.query.thirdSession) {
        sessionStorage.setItem("token", route.query.thirdSession);
      }
      getInfo();
    });

    const logout = () => {
      router.push("./login");
    };
    return {
      current,
      changeKey,
      logout,
      userName,
    };
  },
});
</script>
<style lang="less" scoped>
.home {
  width: 100%;
  background: url(../assets/images/bg.png) center/cover;
  .header {
    display: flex;
    justify-content: space-between;
    height: 100px;
    > img {
      margin: 12px 32px;
    }
    > div {
      display: flex;
      justify-content: space-between;
      > span {
        height: 100px;
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 100px;
        padding: 0 20px;
      }
    }
  }
  .content {
    min-height: 1000px;
    padding: 20px;
    > div {
      background: #fcfdfe99;
    }
  }
}
::v-deep(.ant-menu-horizontal) {
  border: none;
}
::v-deep(.ant-menu) {
  background: transparent;
  color: #333333;
  height: 100px;
}

::v-deep(.ant-menu-title-content) {
  height: 100px;
  font-size: 26px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 100px;
  padding: 0 20px;
}

::v-deep(.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected) {
  color: #06502e;
}

::v-deep(
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after
  ) {
  border-bottom: none;
}

::v-deep(.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover) {
  color: #06502e;
}

::v-deep(
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after
  ) {
  border-bottom: none;
}
</style>
